<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">legistlative file</div>
      </v-row>
      <v-row class="">
        <div class="">
          The legislative file contains the history and details for official
          acts, including staff reports, supporting documentation, and links to
          related files. Each file has a unique number for tracking purposes.
        </div>
      </v-row>
    </v-col>

    <div class="pa-2">
      <v-row>
        <v-col> This is file {{ $route.params.no }} </v-col>
      </v-row>

      <v-row>
        <v-col> File contents will appear here </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
